const numberTypeError = 'Value must be a number';
const inputsForFields = {
  COURSE_NAME: {
    name: 'COURSE_NAME',
    type: 'string',
    inputType: 'input',
    mask: null,
    placeholder: 'Course Name',
    rules: { type: 'string' },
  },
  COURSE_FEES: {
    name: 'COURSE_FEES',
    type: 'currency',
    inputType: 'input',
    placeholder: 'Course Fees',
    mask: 'currency',
    rules: { type: 'number', typeError: numberTypeError },
  },
  COURSE_LABS: {
    name: 'COURSE_LABS',
    mask: 'currency',
    type: 'currency',
    inputType: 'input',
    placeholder: 'Course Labs',
    rules: { type: 'number', typeError: numberTypeError },
  },
  COURSE_BOOKS: {
    name: 'COURSE_BOOKS',
    inputType: 'input',
    placeholder: 'Course Books',
    mask: 'currency',
    type: 'currency',
    rules: { type: 'number', typeError: numberTypeError },
  },
  COURSE_GRADE: {
    name: 'COURSE_GRADE',
    inputType: 'select',
    type: 'string',
    placeholder: 'Grade',
    mask: null,
    rules: { type: 'string' },
    options: [{ value: 'A' }, { value: 'B' }, { value: 'C' }, { value: 'D' }, { value: 'F' }],
    selectionMode: 'single',
  },
  COURSE_CREDIT: {
    name: 'COURSE_CREDIT',
    inputType: 'input',
    placeholder: 'Course Credit',
    mask: null,
    type: 'number',
    rules: { type: 'number', typeError: numberTypeError },
  },
  COURSE_NUMBER: {
    name: 'COURSE_NUMBER',
    inputType: 'input',
    type: 'string',
    rules: { type: 'string' },
    placeholder: 'Number',
    mask: null,
  },
  COURSE_TUITION: {
    name: 'COURSE_TUITION',
    inputType: 'input',
    placeholder: 'Course Tuition',
    mask: null,
    type: 'currency',
    rules: { type: 'string' },
  },
  COURSE_SOFTWARE: {
    name: 'COURSE_SOFTWARE',
    inputType: 'input',
    placeholder: 'Course Software',
    mask: 'currency',
    type: 'currency',
    rules: { type: 'number', typeError: numberTypeError },
  },
  COURSE_DESCRIPTION: {
    name: 'COURSE_DESCRIPTION',
    inputType: 'textarea',
    placeholder: 'Course Description',
    mask: null,
    type: 'string',
    rules: { type: 'string' },
  },
};

export { inputsForFields };

export default null; // silence a false warning
