export const or = <A, B>(a?: A, b?: B): A | B => (a || b) as A | B;
export const and = <A, B>(a?: A, b?: B): A | B => (a && b) as A | B;
export const not = <A>(a?: A): boolean => !a;
export const eq = <A>(a: A, b: A): boolean => a === b;
export const neq = <A>(a: A, b: A): boolean => a !== b;
export const gt = (a: number, b: number): boolean => a > b;
export const gte = (a: number, b: number): boolean => a >= b;
export const lt = (a: number, b: number): boolean => a < b;
export const lte = (a: number, b: number): boolean => a <= b;

export type Intent = 'primary' | 'success' | 'info' | 'warning' | 'error';
